var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('kendo-datepicker',{attrs:{"footer":_vm.datePickerFooter,"format":'dd/MM/yyyy',"culture":'it-IT',"today":'Oggi'},model:{value:(_vm.dataDa),callback:function ($$v) {_vm.dataDa=$$v},expression:"dataDa"}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('kendo-datepicker',{attrs:{"footer":_vm.datePickerFooter,"format":'dd/MM/yyyy',"culture":'it-IT',"today":'Oggi'},model:{value:(_vm.dataA),callback:function ($$v) {_vm.dataA=$$v},expression:"dataA"}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{attrs:{"block":""},on:{"click":_vm.onApplyFilter}},[_vm._v(_vm._s(_vm.$t("button.applyFilter")))])],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('grid',{attrs:{"cell-render":'defaultCellTemplate',"data-items":_vm.gridData,"pageable":_vm.pageable,"filter":_vm.filter,"filterable":false,"resizable":true,"reorderable":true,"sortable":true,"sort":_vm.sort,"skip":_vm.skip,"take":_vm.take,"total":_vm.total,"columns":_vm.columns,"page-size":_vm.pageSize,"selectable":true,"groupable":{ footer: 'visible' },"group":_vm.group,"expand-field":_vm.expandedField,"detail":'detailTemplate'},on:{"pagechange":_vm.pageChangeHandler,"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange},scopedSlots:_vm._u([{key:"myHeaderCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('header-cell-template',_vm._b({attrs:{"tableName":'commdistr',"sort":_vm.sort},on:{"header-switch-change":_vm.onHeaderSwitchChange,"sort-changed":_vm.onSortChanged,"filter-apply":_vm.onFilterApply,"filter-remove":_vm.onFilterRemove}},'header-cell-template',props,false))]}},{key:"defaultCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('cell-template',_vm._b({attrs:{"showDelete":false},on:{"expand":_vm.expandChange,"click":function($event){return _vm.clickHandler(props.dataItem)}}},'cell-template',props,false))]}},{key:"myFooterCommissioniCommercialiCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',[_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t("grid.groupfootersum"))+" "+_vm._s(_vm.kendop.toString(_vm.getCommissioniCommercialeTotale(), "c")))]),_c('div',{staticClass:"text-right"},[_vm._v(_vm._s("Tot Generale:")+" "+_vm._s(_vm.kendop.toString(_vm.getCommissioniCommercialeGenerale(), "c")))])])]}},{key:"myFooterCommissioniDistributoreCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',[_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t("grid.groupfootersum"))+" "+_vm._s(_vm.kendop.toString(_vm.getCommissioniDistributoreTotale(), "c")))]),_c('div',{staticClass:"text-right"},[_vm._v(_vm._s("Tot Generale:")+" "+_vm._s(_vm.kendop.toString(_vm.getCommissioniDistributoreGenerale(), "c")))])])]}},{key:"myFooterProdottiVendutiCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',[_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t("grid.groupfootersum"))+" "+_vm._s(_vm.kendop.toString(_vm.getProdottiVendutiTotale(), "n2")))]),_c('div',{staticClass:"text-right"},[_vm._v(_vm._s("Tot Generale:")+" "+_vm._s(_vm.kendop.toString(_vm.getNumeroAcquistiGenerale(), "n2")))])])]}},{key:"myFooterFatturatoCellTemplate",fn:function(ref){
var props = ref.props;
return [_c('div',[_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t("grid.groupfootersum"))+" "+_vm._s(_vm.kendop.toString(_vm.getFatturatoTotale(), "c")))]),_c('div',{staticClass:"text-right"},[_vm._v(_vm._s("Tot Generale:")+" "+_vm._s(_vm.kendop.toString(_vm.getFatturatoGenerale(), "c")))])])]}},{key:"detailTemplate",fn:function(ref){
var props = ref.props;
return [_c('commerciale-distributore-dettaglio',{attrs:{"dataDa":_vm.dataDa,"dataA":_vm.dataA,"distributoreId":props.dataItem.distributoreID}})]}}])},[_c('grid-toolbar',[_c('b-row',{staticClass:"grid-toolbar",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-button',{staticClass:"ml-2",attrs:{"title":_vm.$t('generico.esporta'),"variant":"success"},on:{"click":_vm.exportExcel}},[_c('b-icon',{attrs:{"icon":"file-earmark-excel-fill"}}),_vm._v(" "+_vm._s(_vm.$t("generico.esporta"))+" ")],1),_c('b-button',{staticClass:"ml-2",attrs:{"title":_vm.$t('generico.azzeraFiltriEOrdinamenti'),"variant":"info"},on:{"click":_vm.clearSortAndFilter}},[_c('b-icon',{attrs:{"icon":"file-earmark-excel-fill"}}),_vm._v(" "+_vm._s(_vm.$t("generico.azzeraFiltri"))+" ")],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-checkbox',{staticClass:"float-right mr-2",attrs:{"color":"theme","switch":""},model:{value:(_vm.soloAttivi),callback:function ($$v) {_vm.soloAttivi=$$v},expression:"soloAttivi"}},[_vm._v(" "+_vm._s(_vm.$t("generico.showactive"))+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }