import api from "@/services/api";
import moment from "moment";
import { Component, Watch } from "vue-property-decorator";
import gridComponentBase from "../components/grid/gridComponentBase.vue";
import { Moment } from 'moment';
import { TextValueItem } from '../models/TextValueItem';
import { PartecipanteItem } from "@/models/ricercaGaranzie/partecipanteItem";

const _ENDPONT = api.endpoint.MY_OVERINS.COMMERCIALI_DISTRIBUTORI,
	_ENDPONT_TOTALI = api.endpoint.MY_OVERINS.COMMERCIALI_DISTRIBUTORI_TOTALI;

@Component({})
export default class CommercialeDistributoriPage extends gridComponentBase {
	soloAttivi: boolean = true;
	dataDa: Date = null;
	dataA: Date = null;

	tipiDistributore: TextValueItem[] = [];
	
	totaliGenerali: any = {};

	aggregates: any[] = [
		{ field: "fatturato", aggregate: "sum" },
		{ field: "prodottiVenduti", aggregate: "sum" },
		{ field: "commissioniCommerciale", aggregate: "sum" },
		{ field: "commissioniDistributore", aggregate: "sum" }
	];
	kendop: any = kendo;

	datePickerFooter = "Oggi - #=kendo.toString(data, 'dd/MM/yyyy') #";

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{ field: "ragioneSociale", title: "Ragione Sociale", headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{
				field: "tipoDistributoreID",
				title: "Tipo",
				width: 100,
				groupable: true,
				values: this.tipiDistributore,
				cell: "defaultCellTemplate",
				sortBy: "tipoDistributore",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				itemsForFilter: this.tipiDistributore,
				filterType: "select"
			},
			{ field: "email", title: "Email", width: 150, headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "partitaIva", title: "Partita Iva", width: 150, headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "provincia", title: "Provincia", width: 150, groupable: true, headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "regione", title: "Regione", width: 150, groupable: true, headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{
				field: "fatturato",
				title: "Fatturato",
				width: 150,
				format: "{0:c}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'c')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'c')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterFatturatoCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "prodottiVenduti",
				title: "Prodotti Venduti",
				width: 150,
				format: "{0:n2}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'n2')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterProdottiVendutiCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "commissioniCommerciale",
				title: "Commiss. Commerc.",
				width: 150,
				format: "{0:c}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'c')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'c')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterCommissioniCommercialiCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "commissioniDistributore",
				title: "Commiss. Distrib.",
				width: 150,
				format: "{0:c}",
				aggregates: ["sum"],
				footerTemplate: "Tot: #=kendo.toString(sum,'c')#",
				groupFooterTemplate: "Tot: #=kendo.toString(sum,'c')#",
				headerCell: "myHeaderCellTemplate",
				footerCell: "myFooterCommissioniDistributoreCellTemplate",
				filterable: true,
				export: true,
				filterType: "numeric",
				type: "numeric"
			},
			{
				field: "ultimoAcquisto",
				title: "Ultimo Acquisto",
				width: 150,
				type: "date",
				headerType: "daterange",
				format: "{0:dd/MM/yyyy}",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true
			},
		];
	}


	created() {
		var self = this;
		var p = [
			api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_DISTRIBUTORE).then(res => (self.tipiDistributore = res)),
		];

		this.calcolaTotaliGenerali();

		Promise.all([p])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	clearSortOrFilterCustom() {
		this.setDefaultDateTimeRange();
		this.soloAttivi = true;
	}

	setDefaultDateTimeRange() {
		let da = new Date();
		da.setMonth(da.getMonth() - 1);
		this.dataDa = da;
		this.dataA = new Date();
	}

	@Watch("soloAttivi")
	onSoloAttiviChange(newValue: boolean) {
		this.setDefaultPageSize();
		this.getData();
	}

	getData() {
		let startDate = moment()
			.utc()
			.add(-1, "month")
			.format();
		if (this.dataDa) {
			startDate = moment(this.dataDa).format("YYYY-MM-DD");
		}

		let endDate = moment()
			.utc()
			.format();
		if (this.dataA) {
			endDate = moment(this.dataA).format("YYYY-MM-DD");
		}

		let soloAttivi = this.soloAttivi;

		this.getGridData(`${_ENDPONT}?dataDa=${startDate}&dataA=${endDate}&soloAttivi=${soloAttivi}`);
	}

	exportExcel() {
		let startDate = moment()
			.utc()
			.add(-1, "month")
			.format();
		if (this.dataDa) {
			startDate = moment(this.dataDa)
				.utc()
				.format();
		}

		let endDate = moment()
			.utc()
			.format();
		if (this.dataA) {
			endDate = moment(this.dataA)
				.utc()
				.format();
		}

		let soloAttivi = this.soloAttivi;

		this.exportGridExcel(`${_ENDPONT}?dataDa=${startDate}&dataA=${endDate}&soloAttivi=${soloAttivi}`);
	}


	getCommissioniCommercialeTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.commissioniCommerciale ? item.commissioniCommerciale : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	getCommissioniDistributoreTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.commissioniDistributore ? item.commissioniDistributore : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	getProdottiVendutiTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.prodottiVenduti ? item.prodottiVenduti : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	getFatturatoTotale() {
		let result = 0;

		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.fatturato ? item.fatturato : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	onApplyFilter() {
		this.setDefaultPageSize();
		this.getData();
		this.calcolaTotaliGenerali();
	}

	calcolaTotaliGenerali(){
		let startDate = moment()
			.utc()
			.add(-1, "month")
			.format();
		if (this.dataDa) {
			startDate = moment(this.dataDa).format("YYYY-MM-DD");
		}

		let endDate = moment()
			.utc()
			.format();
		if (this.dataA) {
			endDate = moment(this.dataA).format("YYYY-MM-DD");
		}

		let soloAttivi = this.soloAttivi;

		this.totaliGenerali = {};
		var self = this;
		api.get(`${_ENDPONT_TOTALI}?dataDa=${startDate}&dataA=${endDate}&soloAttivi=${soloAttivi}`).then(res =>{
			self.totaliGenerali = res || {};
		})
	}

	getCommissioniCommercialeGenerale() {
		return this.totaliGenerali ? this.totaliGenerali.commissioniCommerciale || 0 : 0;
	}

	getCommissioniDistributoreGenerale() {
		return this.totaliGenerali ? this.totaliGenerali.commissioniDistributore || 0 : 0;
	}

	getNumeroAcquistiGenerale() {
		return this.totaliGenerali ? this.totaliGenerali.numeroAcquisti || 0 : 0;
	}

	getFatturatoGenerale() {
		return this.totaliGenerali ? this.totaliGenerali.fatturato || 0 : 0;
	}
}
